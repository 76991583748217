import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import TechTags from "../../components/techTags";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Google TVC`}</h1>
    <h2>{`Summary`}</h2>
    <p>{`After my time at Epikso, I was thrilled to accept a contracting role with Google,
working under the company `}<a parentName="p" {...{
        "href": "https://www.infoobjects.com/"
      }}>{`InfoObjects`}</a>{`.
As a member of the Stadia Video Quality team, I used to serve as a Software Engineer,
in charge of a ground proff data gethering tool for supervised learning. With this data,
we improve Google's AI compressor used in products like Stadia and YouTube.`}</p>
    <p>{`Through this experience, I've been able to expand my knowledge of video technology,
including the different types of compressors, bitrates, and densities, and how these factors impact the overall quality of video content.`}</p>
    <p>{`One of the most significant takeaways from my time at Google has been the transformative power of design docs.
By providing clarity before tackling a problem, design documentation can help streamline implementation and lead to cleaner,
more efficient code.`}</p>
    <h2>{`Challenges`}</h2>
    <p>{`Of course, this journey has not been without its challenges. Two of the most significant obstacles
I've faced thus far have been creating a high-bitrate synchronous multiple video playback and navigating
the complexities of changing countries.`}</p>
    <TechTags title="Technologies" tags={["AngularJS", "Python", "OneStack", "Video Decoding", "GRPC", "Protobuf", "Spaner"]} mdxType="TechTags" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      